export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-speedometer'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Management Dashboard  ',
        to: '/management-dashboard',
        icon: 'cil-speedometer'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Job']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Jobs',
        to: '/job-list?locationRegion=%5B1%5D',
        icon: 'cil-library'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Job Details',
        to: '/job-details-dummy',
        icon: 'cil-library'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Candidate']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Candidates',
        to: '/candidate-list?candidateType=[1]',
        icon: 'cil-people'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Candidate Details',
        to: '/candidate-detail-dummy',
        icon: 'cil-user-follow'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Client']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Organisations',
        to: '/under-construction?org=1',
        icon: 'cil-hospital'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Add Organisation',
        to: '/under-construction?add_org=1',
        icon: 'cil-plus'
      }
    ]
  }
]