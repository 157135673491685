<template>
  <CSidebar class="bg-clr" fixed :minimize="minimize" :show.sync="show">
    <CSidebarHeader class="bg-clr" :withSubheader="subheader">
      <div class="col-12 logo">
        <div>
          <img
            class="mw-100 h-auto"
            :src="getHeaderLogo"
            @error="$event.target.src = '/img/talentfind_logo2.png'"
          />
        </div>
      </div>
    </CSidebarHeader>
    <CRenderFunction flat :content-to-render="sideBarNav" />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="minimize = !minimize"
    />
  </CSidebar>
</template>

<script>
import nav from "./_nav";
import { mapGetters } from "vuex";
import { Role } from "@/helpers/helper";
export default {
  name: "TheSidebar",
  data() {
    return {
      minimize: false,
      subheader: true,
      nav,
      show: false,
    };
  },
  computed: {
    ...mapGetters([
      "getRoleScopes",
      "getCandidateListUrlBasedOnRole",
      "getIsParentUser",
      "getImages",
    ]),
    getHeaderLogo() {
      return this.getImages?.header_logo;
    },
    dashboardNav() {
      let role = this.getRoleScopes[0];

      if (Role.systemAdmin === role) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Dashboard"],
          },
          {
            _name: "CSidebarNavItem",
            name: "Admin Dashboard",
            to: "/dashboard",
            icon: "cil-library",
          },
        ];
      } else if ([Role.customerAdmin, Role.customerRecruiter].includes(role)) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Dashboard"],
          },
          {
            _name: "CSidebarNavItem",
            name: "Recruiter Dashboard",
            to: "/recruiter-dashboard",
            icon: "cil-library",
          },
        ];
      }

      return [];
    },
    candidateNav() {
      let role = this.getRoleScopes[0];
      if (role === Role.customerAdmin || role === Role.customerRecruiter) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Candidates"],
          },
          {
            _name: "CSidebarNavItem",
            name: "Talent Pool",
            to: this.getCandidateListUrlBasedOnRole,
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "Recruitment Status",
            to: "/candidates-status?status=applied",
            icon: "cil-library",
          },
        ];
      } else if (role === Role.systemAdmin) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Candidates"],
          },
          {
            _name: "CSidebarNavItem",
            name: "Talent Pool",
            to: this.getCandidateListUrlBasedOnRole,
            icon: "cil-library",
          },
        ];
      }
      return [];
    },
    hospitalNav() {
      let role = this.getRoleScopes[0];
      // hot fix in production
      // if customerAdmin or customerRecruiter for now we're removing hospital menu
      if (role === Role.customerAdmin || role === Role.customerRecruiter) {
        return [];
      }
      if (role === Role.customerAdmin) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Hospitals"],
          },
          {
            _name: "CSidebarNavItem",
            name: "Add Hospital Recruiters",
            to: "/add-hospital-recruiters",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "Registered Hospital Recruiters",
            to: "/hospital-recruiters-list",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "Registered Hospital Recruiters",
            to: "/hospital-recruiters-list",
            icon: "cil-library",
          },
        ];
      } else if (role === Role.systemAdmin) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Hospitals"],
          },
          {
            _name: "CSidebarNavItem",
            name: "Add Hospital",
            to: "/add-hospital",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "Add Hospital Administrator",
            to: "/add-hospital-admin",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "Registered Hospitals",
            to: "/hospital-list",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "Registered Hospital Administrators",
            to: "/hospital-admin-list",
            icon: "cil-library",
          },
        ];
      }

      return [];
    },
    jobsNav() {
      let role = this.getRoleScopes[0];

      if (role === Role.systemRecruiter) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Jobs"],
          },
          {
            _name: "CSidebarNavItem",
            name: "Create Job",
            to: "/add-job?step=0",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "List Jobs",
            to: "/job-list",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavTitle",
            _children: ["Job Board"],
          },
          {
            _name: "CSidebarNavItem",
            name: "Active Jobs",
            to: "/jobs-list/status/active",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "Inactive Jobs",
            to: "/jobs-list/status/inactive",
            icon: "cil-library",
          },
        ];
      } else if (
        role === Role.customerAdmin ||
        role === Role.customerRecruiter
      ) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Jobs"],
          },
          {
            _name: "CSidebarNavItem",
            name: "Create Job",
            to: "/add-job?step=0",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "List Jobs",
            to: "/job-list",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavTitle",
            _children: ["Job Board"],
          },
          {
            _name: "CSidebarNavItem",
            name: "Active Jobs",
            to: "/jobs-list/status/active",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "Inactive Jobs",
            to: "/jobs-list/status/inactive",
            icon: "cil-library",
          },
        ];
      }else if(role === Role.systemAdmin){
         return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Jobs"],
          },
          {
            _name: "CSidebarNavItem",
            name: "List Jobs",
            to: "/job-list",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavTitle",
            _children: ["Job Board"],
          },
          {
            _name: "CSidebarNavItem",
            name: "Active Jobs",
            to: "/jobs-list/status/active",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "Inactive Jobs",
            to: "/jobs-list/status/inactive",
            icon: "cil-library",
          },
        ];
      }
      /*
      <!-- Hiding this, Job Board to be launched later - by venki -->
        else if (role === Role.systemCandidate || role === Role.customerCandidate) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Jobs"]
          },
          {
            _name: "CSidebarNavItem",
            name: "Job board",
            to: "/public/job-board",
            icon: "cil-library"
          }
        ];
      } */
      return [];
    },
    facilityNav() {
      let role = this.getRoleScopes[0];
      // hot fix in production
      // if customerAdmin or customerRecruiter for now we're removing facility menu
      if (role === Role.systemAdmin) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Facilities"],
          },
          {
            _name: "CSidebarNavItem",
            name: "Add Facility",
            to: "/add-facility",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "Reports",
            to: "/facility-list",
            icon: "cil-library",
          },
        ];
      } else if (role === Role.customerAdmin) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Facilities"],
          },
          {
            _name: "CSidebarNavItem",
            name: "Add Facility Details",
            to: "/facility-detail",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "Add Facility Admin / User",
            to: "/add-facilityuser",
            icon: "cil-library",
          },
        ];
      }

      return [];
    },
    customerNav() {
      let role = this.getRoleScopes[0];
      if (role === Role.systemAdmin) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Facilities"],
          },
          {
            _name: "CSidebarNavItem",
            name: "Add Facility",
            to: "/add-facility",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "Facility Config & Reports",
            to: "/facility-list",
            icon: "cil-library",
          },
        ];
      }

      return [];
    },
    organisationNav() {
      if (this.getIsParentUser === true) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Organisation"],
          },
          {
            _name: "CSidebarNavItem",
            name: "Add Organisation",
            to: "/add-organisation",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "List Organisation",
            to: "/organisation-list",
            icon: "cil-library",
          },
        ];
      }
      return [];
    },
    contactUserNav() {
      let role = this.getRoleScopes[0];
      if (role === Role.customerAdmin) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Users"],
          },
          {
            _name: "CSidebarNavItem",
            name: "Add User",
            to: "/add-contactuser",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "User List",
            to: "/list-contactuser",
            icon: "cil-library",
          },
        ];
      }
      return [];
    },

    config() {
      let role = this.getRoleScopes[0];
      if (role === Role.systemAdmin) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Config"],
          },
          {
            _name: "CSidebarNavItem",
            name: "Public Job Board",
            to: "/config-public-job-board",
            icon: "cil-library",
          },
        ];
      }

      return [];
    },
    sideBarNav() {
      let role = this.getRoleScopes[0];
      if (role === Role.systemCandidate || role === Role.customerCandidate) {
        return [
          {
            _name: "CSidebarNav",
            _children: [...this.jobsNav],
          },
        ];
      } else {
        return [
          {
            _name: "CSidebarNav",
            _children: [
              ...this.dashboardNav,
              ...this.candidateNav,
              ...this.customerNav,
              // ...this.hospitalNav
              //Temporarily Removed for R1 release
              //...this.facilityNav,
              ...this.jobsNav,
              ...this.organisationNav,
              ...this.contactUserNav,
              ...this.config,
            ],
          },
        ];
      }
    },
  },
  mounted() {
    this.$root.$on("toggle-sidebar", () => {
      const sidebarOpened = this.show === true || this.show === "responsive";
      this.show = sidebarOpened ? false : "responsive";
    });
    this.$root.$on("toggle-sidebar-mobile", () => {
      const sidebarClosed = this.show === "responsive" || this.show === false;
      this.show = sidebarClosed ? true : "responsive";
    });
  },
};
</script>
